// const BASE_URL = 'http://192.168.1.24:5000'
const BASE_URL = 'https://api.yoojet.com'
// const BASE_URL = 'https://demoapi.yoojet.com'
//  const BASE_URL = 'http://192.168.1.111:5000'
// const BASE_URL = 'http://119.3.210.33:8800'
/* 加密接口 */
const VERI_URL = 'https://veri.yoojet.com'
/* 区块链接口 */
const CHAIN_URL = 'http://chainapi.yoojet.com'
export default {
  BASE_URL,
  VERI_URL,
  CHAIN_URL
}
