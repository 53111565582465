import Vue from 'vue'
import VueRouter from 'vue-router'
import menu from '../views/menu.vue'
import { Message } from 'element-ui'

Vue.use(VueRouter)
const statistics = () => import('@/views/statistics/statistics.vue') //存证
const chain = () => import('@/views/chain/chain.vue') //存证
const group = () => import('@/views/group/group.vue') //电子回执订阅
const remind = () => import('@/views/remind/remind.vue') //电子回执催签提醒
const waybill = () => import('@/views/waybill/waybill.vue') //电子回执
const waybillCopy = () => import('@/views/waybill/waybillCopy.vue') //电子回执
const Reconciliation = () => import('@/views/Reconciliation/Reconciliation.vue') //对账函
const ArrearList = () => import('@/views/ArrearList/ArrearList.vue') //对账记录页面
const CollectMoney = () => import('@/views/CollectMoney/CollectMoney.vue') //销账记录页面
const ReconciliationFormCopy = () => import('@/views/Reconciliation/ReconciliationFormCopy.vue') //对账函客户端
const help = () => import('@/views/help/help.vue') //帮助
const information = () => import('@/views/information/information.vue')
const clienteleInfo = () => import('@/views/clienteleInfo/clienteleInfo.vue') //客户信息
const clienteleGroup = () => import('@/views/clienteleInfo/group/group.vue') //客户信息
const clienteleSolution = () => import('@/views/clienteleInfo/solution/solution.vue') //客户信息
const GoodsInfo = () => import('@/views/GoodsInfo/GoodsInfo.vue') //货品信息
const ContactPerson = () => import('@/views/clienteleInfo/ContactPerson.vue') //签收人
const address = () => import('@/views/clienteleInfo/address.vue') //地址
const Carrier = () => import('@/views/Carrier/Carrier.vue') //承运方
const set = () => import('@/views/set/set.vue') //设置
const Register = () => import('@/views/Register/Register.vue') //注册
const Purchase = () => import('@/views/Purchase/Purchase.vue') //购买
const Article = () => import('@/views/Register/Article.vue') //条款
const Privacy = () => import('@/views/Register/Privacys.vue') //隐私
const UserManagement = () => import('@/views/UserManagement/UserManagement.vue') //注册
const SendSMS = () => import('@/views/SendSMS/SendSMS.vue') //发送短信页面
const SendSMSCopy = () => import('@/views/SendSMS/SendSMSCopy.vue') //发送对账函页面
const SendSMSMobileCopy = () => import('@/views/SendSMS/SendSMSMobileCopy.vue') //发送对账函移动端页面
const SendSMSMobile = () => import('@/views/SendSMS/SendSMSMobile.vue') //微信分享展示页面
const SendSMSPublic = () => import('@/views/SendSMS/SendSMSPublic.vue') //微信分享展示页面
const ReconArrearShare = () => import('@/views/ArrearList/ReconArrearShare.vue') //对账记录分享展示页面
const PrintCode = () => import('@/views/PrintCode/PrintCode.vue') //打印二维码
const HomePage = () => import('@/views/HomePage.vue') //宣传页
const domprint = () => import('../views/domprint/domPrint.vue') //
const LoginPage = () => import('../views/LoginPage.vue') //同域名网站跳转页
const Forget = () => import('../views/Forget/Forget.vue') //忘记密码
const PublicAccountRegister = () => import('../views/PublicAccount/Register.vue') //公众号注册
const PublicAccountUserRegister = () => import('../views/PublicAccount/UserRegister.vue') //公众号用户注册
const HistoricalRecord = () => import('../views/PublicAccount/HistoricalRecord.vue') //公众号记录
const RetrievePassword = () => import('../views/PublicAccount/RetrievePassword.vue') //公众号找回密码
const getOpenId = () => import('../views/PublicAccount/getOpenId.vue') //获取openId
const MyReturnReceipt = () => import('../views/PublicAccount/MyReturnReceipt.vue') //我的回执
const facePassLoading = () => import('../views/facePass/loading') //
const facePassError = () => import('../views/facePass/error') //
const AppReceipt = () => import('../views/App/Receipt') // app小票
const AppReconciliation = () => import('../views/App/Reconciliation') // app催款
const AppSales = () => import('../views/App/Statistics/Sales.vue') // app统计/收款
const AppCredit = () => import('../views/App/Statistics/Credit.vue') // app统计/赊货统计
const AppSellings = () => import('../views/App/Statistics/selling.vue') // app统计/销售
const AppBalance = () => import('../views/App/Statistics/Balance.vue') // app统计/欠款
const AppOverdues = () => import('../views/App/Statistics/Overdues.vue') // app统计/逾期
const AppOverduesDetail = () => import('../views/App/Statistics/OverduesDetail.vue') // app统计/逾期明细
const AppOverdueDays = () => import('../views/App/Statistics/OverdueDays.vue') // app统计/逾期天数
const Overdue = () => import('../views/Overdue/Overdue.vue') // 逾期
const SaleInfo = () => import('../views/Overdue/SaleInfo.vue') // 逾期单据详情
const ShareOverdue = () => import('../views/Overdue/ShareOverdue.vue')//逾期分享页面
// pc端统计
const statisticsSales = () => import('@/views/statistics/statisticsSales.vue') //pc收款统计
const statisticsSells = () => import('@/views/statistics/statisticsSells.vue') //pc销售统计
const statisticsBlance = () => import('@/views/statistics/statisticsBlance.vue') //pc欠款统计
const statisticsOverdue = () => import('@/views/statistics/statisticsOverdue.vue') //pc逾期统计
const statisticsOverdueDays = () => import('@/views/statistics/statisticsOverdueDays.vue') //pc逾期天数统计






const routes = [
  { path: '/Login', name: 'Not1', component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue') }, // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited. component: () =>   import(/* webpackChunkName: "about" */ '../views/Login.vue'),   },
  {
    path: '/', name: 'menu', component: menu, redirect: (to) => {   // 方法接收目标路由作为参数
      // return 重定向的字符串路径/路径对象
      // return { path: '/HomePage', query: { q: to.params.searchText } }
      return { path: '/Login', query: { q: to.params.searchText } }
    }, children: [
      { path: 'statistics', component: statistics, name: '发货统计' },
      { path: 'statisticsSales', component: statisticsSales, name: '收款统计', meta: { title: '收款统计', path: '/statisticsSales' } },
      { path: 'statisticsSells', component: statisticsSells, name: '销售统计' },
      { path: 'statisticsBlance', component: statisticsBlance, name: '欠款统计' },
      { path: 'statisticsOverdue', component: statisticsOverdue, name: '逾期统计' },
      { path: 'statisticsOverdueDays', component: statisticsOverdueDays, name: '逾期天数统计' },
      { path: 'chain', component: chain, name: '存证' },
      { path: 'group', component: group, name: '分组' },
      { path: 'remind', component: remind, name: '提醒' },
      { path: 'waybill', component: waybill, name: '出库单' },
      { path: 'waybillCopy', component: waybillCopy, name: '出库单1' },
      { path: 'Reconciliation', component: Reconciliation, name: '对账函' },
      { path: 'ArrearList', component: ArrearList, name: '对账记录' },
      { path: 'CollectMoney', component: CollectMoney, name: '销账记录' },
      { path: 'help', component: help, name: '帮助' },
      { path: 'information', component: information, name: '我的账户' },
      { path: 'clienteleInfo', component: clienteleInfo, name: '客户信息' },
      { path: 'clienteleInfo/group', component: clienteleGroup, name: '客户分组' },
      { path: 'clienteleInfo/solution', component: clienteleSolution, name: '查询方案' },
      { path: 'GoodsInfo', component: GoodsInfo, name: '货品信息' },
      { path: 'ContactPerson', component: ContactPerson, name: '签收人' },
      { path: 'UserManagement', component: UserManagement, name: '用户管理' },
      { path: 'address', component: address, name: '地址' },
      { path: 'Carrier', component: Carrier, name: '承运方' },
      { path: 'set', component: set, name: '设置' },
      { path: 'Overdue', component: Overdue, name: '' },//逾期
    ],
  },
  { path: '/ReconciliationFormCopy', component: ReconciliationFormCopy, name: '客户端' },
  { path: '/Forget', component: Forget, name: 'Not3' },
  { path: '/Register', component: Register, name: 'Not4' },
  { path: '/Purchase', component: Purchase, name: 'Not5' },
  { path: '/Article', component: Article, name: 'Not6' },
  { path: '/Privacy', component: Privacy, name: 'Not7' },
  { path: '/SendSMS', component: SendSMS, name: 'Not8' },
  { path: '/SendSMSCopy', component: SendSMSCopy, name: 'Not9' },
  { path: '/ArrearList', component: ArrearList, name: 'Not10' },
  { path: '/CollectMoney', component: CollectMoney, name: 'Not11' },
  { path: '/SendSMSMobileCopy', component: SendSMSMobileCopy, name: 'Not12' },
  { path: '/SendSMSMobile', component: SendSMSMobile, name: 'Not13' },
  { path: '/SendSMSPublic', component: SendSMSPublic, name: 'Not14' },
  { path: '/ReconArrearShare', component: ReconArrearShare, name: 'Not15' },//微信分享展示页面
  { path: '/PrintCode', component: PrintCode, name: 'Not16' },
  { path: '/HomePage', component: HomePage, name: 'Not17' },
  { path: '/domprint', component: domprint },
  { path: '/Login/LoginPage', component: LoginPage, name: '登录跳转/Not19' }, // 7003跳转页
  { path: '/PublicAccount/Register', component: PublicAccountRegister, name: '公众号注册/Not20' },
  { path: '/PublicAccount/UserRegister', component: PublicAccountUserRegister, name: '公众号用户注册/Not21' },
  { path: '/PublicAccount/HistoricalRecord', component: HistoricalRecord, name: '公众号历史记录/Not22' },
  { path: '/PublicAccount/RetrievePassword', component: RetrievePassword, name: '公众号密码找回/Not23' },
  { path: '/PublicAccount/getOpenId', component: getOpenId, name: '公众号获取用户ID/Not24' },
  { path: '/PublicAccount/MyReturnReceipt', component: MyReturnReceipt, name: '公众号我的回执/Not25' },
  { path: '/pass/facePassLoading', component: facePassLoading, name: 'Not26' },
  { path: '/pass/facePassError', component: facePassError, name: 'Not27' },
  { path: '/App/Receipt', component: AppReceipt, name: 'Not28' },
  { path: '/App/Reconciliation', component: AppReconciliation, name: 'Not29' },
  { path: '/App/Statistics/AppSales', component: AppSales, name: '' },//收款统计app报表
  { path: '/App/Statistics/AppSellings', component: AppSellings, name: '' },//销售统计app报表
  { path: '/App/Statistics/AppBalance', component: AppBalance, name: '' },//欠款统计app报表
  { path: '/App/Statistics/AppOverdues', component: AppOverdues, name: '' },//逾期统计app报表
  { path: '/App/Statistics/AppOverduesDetail', component: AppOverduesDetail, name: '' },//逾期明细统计app报表
  { path: '/App/Statistics/AppOverdueDays', component: AppOverdueDays, name: '' },//逾期天数统计app报表
  { path: '/App/Statistics/AppCredit', component: AppCredit, name: '' },//赊货统计app报表
  { path: '/SaleInfo', component: SaleInfo, name: '' },//逾期单据详情
  { path: '/ShareOverdue', component: ShareOverdue, name: '' },//逾期分享页面
]
const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
})
let routerList = [
  '/Login',
  '/Forget',
  '/Register',
  '/Purchase',
  '/Article',
  '/Privacy',
  '/SendSMS',
  '/SendSMSCopy',
  '/ArrearList',
  '/CollectMoney',
  '/SendSMSMobileCopy',
  '/SendSMSMobile',
  '/SendSMSPublic',
  '/ReconArrearShare',
  '/PrintCode',
  '/HomePage',
  '/home.html',
  '/enroll.html',
  '/Login/LoginPage',
  '/PublicAccount/Register',
  '/PublicAccount/UserRegister',
  '/PublicAccount/HistoricalRecord',
  '/PublicAccount/RetrievePassword',
  '/PublicAccount/getOpenId',
  '/PublicAccount/MyReturnReceipt',
  '/pass/facePassLoading',
  '/pass/facePassError',
  '/App/Receipt',
  '/App/Reconciliation',
]
router.beforeEach((to, from, next) => {
  console.log('获取路由文件里的路由',to);
  let token = sessionStorage.getItem('token')

  // 检测是否为纯数字  纯数字跳转到签收页面
  if (!isNaN(Number(to.fullPath.slice(1, to.fullPath.length)))) {
    next('/App/Receipt?Id=' + to.fullPath.slice(1, to.fullPath.length) +'&billId=1')
  } else if (to.path == '/information' && from.path == '/Login') {
    next()
  } else if (to.path == '/information' && from.path == '/' && !token) {
    next('/Login')
    // } else if (routerList.findIndex((item) => item == to.path) !== -1) {
  } else if (to.name && to.name.indexOf('Not') >= 0) {
    console.log('进入页面')
    next()
  } else {
    if (to.fullPath.indexOf('token=') !== -1) {
      token = to.fullPath.split('token=')[1]
    }
    if (!token) {
      Message.error('登录信息失效,请重新登录')   // next('/Login')   window.open('http://msq.yoojet.com/Login?tap=1', '_self')   return
    } next()
  }
})

export default router
