import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// import './elmentColor/index.css'
import http from './api/http' 
import Print from 'vue-print-nb'
import global_ from './config/global'
import {
  Field,
  Button,
  Col,
  Row,
  Toast,
  Uploader,
  List,
  Cell,
  CellGroup,
  Popup,
  Dialog,
} from 'vant'
import 'vant/lib/index.css'
import vueEsign from 'vue-esign'
import htmlToPdf from './utils/htmlToPdf'
import './antd'
import VueDragResize from 'vue-drag-resize'
import JsEncrypt from 'jsencrypt' // 加密

import elTableInfiniteScroll from "el-table-infinite-scroll";
// 注册全部面包屑导航
import  MyBreadcrumb from '@/components/myBreadcrumb'
Vue.component('my-breadcrumb', MyBreadcrumb)


Vue.use(elTableInfiniteScroll);

Vue.component('vue-drag-resize', VueDragResize)
Vue.prototype.$JsEncrypt = JsEncrypt
Vue.prototype.$baseUrl = global_.BASE_URL
Vue.prototype.$http = http
Vue.config.productionTip = false
// 需要添加Vue.config.devtools = true;  浏览器才会显示vue-devtools插件
Vue.config.devtools = true;

// console.log = function () {}

Vue.use(Print)
Vue.use(ElementUI)
Vue.use(htmlToPdf)
Vue.use(vueEsign)
Vue.use(Button)
Vue.use(Field)
Vue.use(Col)
Vue.use(Row)
Vue.use(Toast)
Vue.use(Uploader)
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(List)
Vue.use(Popup)
Vue.use(Dialog)
new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app')
